<template>
  <form id="newRecord" @submit.prevent="saveOrUpdateAspecto">
    <c-422-errors :errors="errors" />
    <div class="mb-3">
      <label for="name" class="form-label">Nombre:</label>
      <input v-model="aspecto.name" class="form-control" id="name" required />
    </div>
    <c-primary-button class="w-100" :disabled="loading">
      Guardar
    </c-primary-button>
  </form>
</template>
<script>
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import C422Errors from "@/components/C422Errors";
import { create, update, find } from "@/services/aspectos";
import { showNotification } from "@/services/notification";

export default {
  components: {
    C422Errors,
    CPrimaryButton,
  },
  data() {
    return {
      errors: {},
      loading: false,
      aspecto: {
        name: "",
      },
      aspectoId: this.$route.params.id,
    };
  },
  mounted() {
    this.getAspecto();
  },
  methods: {
    getAspecto() {
      if (!this.aspectoId) {
        return;
      }
      find(this.aspectoId).then((response) => {
        this.aspecto = response.data;
      });
    },
    saveOrUpdateAspecto() {
      if (this.aspectoId) {
        this.updateAspecto();
      } else {
        this.saveAspecto();
      }
    },
    saveAspecto() {
      create(this.aspecto).then(() => {
        showNotification(201);
        this.$router.push("/admin/configuraciones/aspectos");
      });
    },
    updateAspecto() {
      update(this.aspectoId, this.aspecto).then(() => {
        showNotification(201);
        this.$router.push("/admin/configuraciones/aspectos");
      });
    },
  },
};
</script>
