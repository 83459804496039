<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <c-card title="Editar aspecto">
          <aspecto-form />
        </c-card>
      </div>
    </div>
  </div>
</template>
<script>
import AspectoForm from "@/components/Forms/AspectoForm";
import CCard from "@/components/CCard.vue";

export default {
  components: {
    AspectoForm,
    CCard,
  },
};
</script>
